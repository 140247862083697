import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CurrentUserDataService {
    private currentUserData = new BehaviorSubject<any | null>(null);

    constructor() {}

    public setCurrentUserData(value: any): any | null {
        this.currentUserData.next(value);
    }

    public getCurrentUserData(): any {
        return this.currentUserData.getValue()
    }

}