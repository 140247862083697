import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import {ApiService} from "./api.service";


@Injectable({
  providedIn: 'root'
})

export class MainUserIdService {
  private _client_id = new BehaviorSubject<number>(0);
  public client_id$ = this._client_id.asObservable();

  private client_color_profile = new BehaviorSubject<any>({"bg-color": '#fff', "font-color": '#8f9bb3'});
  public color_profile$ = this.client_color_profile.asObservable();
  private currentUser = new BehaviorSubject<null>(null);
  public userData = this.currentUser.asObservable();

  private _frontends = new BehaviorSubject<any>([]);
  public frontends = this._frontends.asObservable();


  private _clients = new BehaviorSubject<any>([]);
  public allowed_clients$ = this._clients.asObservable()


  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {

  }

  public getClients() {
    // set clients with api call
    this.apiService.getMainUsersData().subscribe(
      (res: any) => {
        const userData = res.data.current_user;
        this.currentUser.next(userData);
        this._frontends.next(res.data.frontends);

        const clientsString = userData.frontends_allowed;
        const frontends = res.data.frontends;

         let selectedApp = frontends.find(x=>x.id == userData.frontend_id);

         this.client_color_profile.next({
          "bg-color": selectedApp.color,
          "font-color": selectedApp.font_color,
        });

        const clientsArr = clientsString.split(',');
        const tempArr = [];

        clientsArr.forEach(cl => {
          let resData = frontends.find(x=>x.id == Number(cl));
          if (resData) tempArr.push(resData);
        })

        this._clients.next(tempArr);

       // if no client id
       if (this.getClientId(true) == 0) {
         // try getting it from route
         this.route.queryParams.pipe(take(1)).subscribe(params => {
           let res_data = frontends.find(x=>x.id == +params['ott_mu']);

           // client id in params exists
           if (+params['ott_mu'] > 0 && res_data) {


             this.setClientId(+params['ott_mu']);
            this.client_color_profile.next({
              "bg-color": res_data.color,
              "font-color": res_data.font_color,
            });
           }
           else if(+sessionStorage.getItem('ott_mu') > 0) {
             // set client id from api side
             this.setClientId(+sessionStorage.getItem('ott_mu'));

             let res_data = frontends.find(x=>x.id == +sessionStorage.getItem('ott_mu'));
             this.client_color_profile.next({
                "bg-color": res_data.color,
                "font-color": res_data.font_color,
              });
           }
           else {
             // set client id from api side
             this.setClientId(userData.frontend_id);

             let res_data = frontends.find(x=>x.id == userData.frontend_id);
             this.client_color_profile.next({
                "bg-color": res_data.color,
                "font-color": res_data.font_color,
            });
           }


        });
       } else if (this.getClientId() > 0) {
         let res_data = frontends.find(x=>x.id == this.getClientId());
         this.client_color_profile.next({
              "bg-color": res_data.color,
              "font-color": res_data.font_color,
         });
       }
      }
    )
  }



  public setClientId(val: number, reload = false) {
    this._client_id.next(val);
    sessionStorage.setItem('ott_mu', val.toString());
    // set or update url params
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ottmu: ""+val
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });

    if (reload) {
      setTimeout(()=> {
        window.location.reload();
      }, 350);
    }

  }


  public getClientId(as_base: boolean = false): number {

    if (!this._client_id.getValue() && !as_base) {
      if (+sessionStorage.getItem('ott_mu') > 0) {
        this._client_id.next(+sessionStorage.getItem('ott_mu'));
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            ottmu: ""+sessionStorage.getItem('ott_mu')
          },
          replaceUrl: true,
          queryParamsHandling: 'merge',
          // preserve the existing query params in the route
          skipLocationChange: false
          // do not trigger navigation
        });
      }

      const savedAppId = sessionStorage.getItem('ott_mu');
      //if no ott_mu in sessionStorage
      if (this._client_id.getValue() == 0 && savedAppId == null) {
        this.route.queryParams.subscribe(params => {
          const paramAppId = params['ottmu'];
          if (paramAppId) {
            this._client_id.next(+paramAppId);
            sessionStorage.setItem('ott_mu', JSON.stringify(paramAppId));
          }
      });
    }

    }

    return this._client_id.getValue();
  }

  public setColorApp(id: number) {
    const frontends = this._clients.getValue();
    const frontend = frontends.find(item => item.id === id);
    this.client_color_profile.next({
              "bg-color": frontend.color,
              "font-color": frontend.font_color,
         });
  }

}
