import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject, combineLatest } from 'rxjs';
import { tap, defaultIfEmpty } from 'rxjs/operators'
import { ApiService } from "./api.service";
import {log} from "util";

export interface ChatterStatus {
  id: number;
  name: string;
  description: string;
  status: number;
  created_at?: Date;
  updated_at?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ChatterStatusService {
  public status = new Subject<ChatterStatus>();
  public currentStatus;
  
  public statuses = new BehaviorSubject<ChatterStatus[] | null>(null);
  private currentUser = new BehaviorSubject<any|null>(null);

  constructor(
    private apiService: ApiService
  ) {

    this.status.subscribe(chatterStatus => {
      this.currentStatus = chatterStatus.status;
    });

    combineLatest(
      this.apiService.getAllChatterStatuses(),
      this.apiService.getMyProfile()
    ).subscribe(([ statuses, currentUser ]: any) => {
        let statusesSorted = statuses.data.sort((a, b) => a.status - b.status);
        let currentUserData = currentUser.data;

        this.statuses.next(statusesSorted)
        this.currentUser.next(currentUserData);

        statusesSorted.forEach(chatterStatus => {
          if (chatterStatus.status === currentUserData?.chatter_status_id) {
            this.status.next(chatterStatus)
            this.currentUser.complete()
          }
        })
      }
    )
  }

  public setChatterStatus(status: number): void {
    this.statuses.subscribe(statuses => {
      statuses.forEach(chatterStatus => {
        if (chatterStatus.status === status) {
          this.status.next(chatterStatus)
        }
      })
    })
  }

}
