import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


// **********************
// PRODUCTION OR DEV
const production = true; // <----------------------------
export const version = '5.3.20';



// PRODUCTION URL
const production_url = 'https://api2.oneteasetech.com';
const production_url2 = 'https://att-api-us-service-app-validations-5bdzc3anxa-uc.a.run.app';


const production_url3 = 'https://ott-api-us-chatterbot-5bdzc3anxa-uc.a.run.app';
// const production_url3 = 'localhost:8000';

/// STAGE URL
/// STAGE URL



const stage_url = 'http://localhost:8000';
// let stage_url = 'https://api-stage.oneteasetech.com';
// let stage_url = 'https://tt1---att-api-us-service-app-1-5bdzc3anxa-uc.a.run.app'


// API CONSTANTS
const apiUrl = production ? production_url + '/back/' : stage_url + '/back/';
const apiUrl2 = production ? production_url2 + '/back/' : stage_url + '/back/';
const apiUrl3 = production ? production_url3 + '/back/' : stage_url + '/back/';
const rootUrl = production ? production_url + '/' : stage_url + '/';
const rootUrl2 = production ? production_url2 + '/' : stage_url + '/';

// **********************


export const dev = {
  auth: apiUrl,
  production: production,
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // BASICS
  baseUrl() {
    return rootUrl;
  }



  // Auth Permissions
  getAllUsers() {
    return this.http.get(apiUrl + 'users/getAll');
  }

  // AUTH
  public authCheckEmail(email: string) {
    return this.http.get(apiUrl + 'auth/check/email/' + email);
  }

  public authCheckUsername(username: string) {
    return this.http.get(apiUrl + 'auth/check/username/' + username);
  }

  public authCheckProfileName(name: string, profile_id: number) {
    return this.http.get(apiUrl + 'auth/check/profileName/' + profile_id + '/' + name);
  }

  public authChangePassword(data: any) {
    // data = {
    //   "password_old": string,
    //   "password_new": string
    //   }
    return this.http.post(apiUrl + 'auth/password/change', data);
  }

  public authChangeEmail(data: any) {
    // data = {
    //   "email_new": string,
    //   "password": string
    //   }
    return this.http.post(apiUrl + 'auth/email/change', data);
  }

  public getMainUsersData() {
    return this.http.get(apiUrl + 'users/frontends/list');
  }

  updateUsersMainUserData(frontend_id: number) {
    return this.http.get(apiUrl + 'users/frontend_id/update/' + frontend_id);
  }



  // DASHBOARD
  getDashboardInfo(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/info', data);
  }

  getTransactionsDetail(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/transactions', data);
  }

  getAffiliatesDetail(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates', data);
  }

  getAffiliatesCsv(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates-csv', data);
  }

  getAffiliatesCsvDecrypt(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates-csv-decrypt', data);
  }

  getAffiliates2Detail(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates2', data);
  }

  getAffiliates2Filters(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates2/filters', data);
  }

  getAffiliates2GraphsDetail(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates2/graphs', data);
  }

  getAffiliates3Detail(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates3', data);
  }

  getAffiliatesTraffic(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates/traffic', data);
  }

  getAffiliatesTraffic2() {
    return this.http.get(apiUrl + 'stats/affiliates/traffic2');
  }


  getAffiliates3Devices() {
    return this.http.get(apiUrl + 'stats/affiliates3/devices');
  }

  getAffiliates3Subs(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates3/subs', data);
  }

  getAffiliates3Presets() {
    return this.http.get(apiUrl + 'stats/affiliates3/presets');
  }

  createAffiliate3Preset(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates3/presets/create', data);
  }

  deleteAffiliate3Preset(id: number) {
    return this.http.delete(apiUrl + 'stats/affiliates3/presets/delete/' + id);
  }

  updateAffiliate3Preset(data: any) {
    return this.http.post(apiUrl + 'stats/affiliates3/presets/update', data);
  }
  getChartData(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/chart', data);
  }

  getUsersDetail(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/users', data);
  }
  getUsersDetailSignup(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/signup', data);
  }
  getUsersDetailSignupChart(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/signup/chart', data);
  }
  getUsersDetailEmailing(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/emailing', data);
  }
  getUsersDetailEmailingChart(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/emailing/chart', data);
  }
  getUsersDetailLanders(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/landers', data);
  }

  getCardDetails() {
    return this.http.get(apiUrl + 'stats/dashboard/card/detail');
  }

  getMessageChartData(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/chart1', data);
  }
  getMessageChart2Data(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/messages/chart2', data);
  }

  getChatterLogsData(data: any) {
    return this.http.post(apiUrl + 'stats/chatter-logs', data)
  }

  getLoginsChartData(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/graph/logins', data)
  }

  getSpamRateChartData(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/graph/spam_rate', data)
  }

  getAdniumStatsChartData(data: any) {
    return this.http.post(apiUrl + 'stats/dashboard/graph/adnium', data)
  }

  getBestSoldProduct(data: any) {
      return this.http.post(apiUrl + 'stats/dashboard/product/sold', data)
  }

  getTopBuyersUsers() {
      return this.http.get(apiUrl + 'stats/dashboard/top_buyers')
  }

  getUsersWithFirstPurchases(data: any) {
      return this.http.post(apiUrl + 'stats/dashboard/first_purchases', data)
  }

  getUsersPurchases(data: any) {
      return this.http.post(apiUrl + 'stats/dashboard/users/purchases', data)
  }

  getFollowUpsStats(data: any) {
      return this.http.post(apiUrl + 'stats/dashboard/followups', data)
  }

  getPaymentProvidersInfo(data: any) {
      return this.http.post(apiUrl + 'stats/dashboard/payment_providers', data)
  }




  // PROFILE
  getMyProfile() {
    return this.http.get(apiUrl + 'profiles/my-profile');
  }


  // PROFILES
  getProfilesBasics() {
    return this.http.get(apiUrl2 + 'profiles/basics');
  }

  searchProfiles(q) {
    return this.http.get(apiUrl + 'profiles/search/' + q);
  }

  searchVirtualProfiles(q) {
    return this.http.get(apiUrl + 'profiles/search/' + q + '?virtual_profiles=1');
  }

  getProfilesByStatus(status: number, skip: number, take: number, search: any = [], val: number = 0) {
    const data: any = {
      status: status,
      skip: skip,
      take: take,
      search: search,
      real_users: (val == 1 ? true : false),
    };

    return this.http.post(apiUrl2 + 'profiles/filter', data);
  }

  changeProfileStatus(data: any) {
    return this.http.post(apiUrl + 'profiles/status/update', data);
  }

  uploadProfileImportFile(data) {
    return this.http.post(apiUrl + 'profiles/import/check-file', data);
  }

  profileUpdate(data: any) {
    return this.http.post(apiUrl2 + 'profiles/update', data);
  }

  profileReport(data: any) {
    // data = {
    //   profile_id: str
    //   message: str
    //   subject: str
    // }
    return this.http.post(apiUrl + 'profile/report', data);
  }

  getProfilesImage(profile_id) {
    return this.http.get(apiUrl + 'profiles/images/' + profile_id);
  }

  getProfileImageBase64(id: any) {
    return this.http.get(rootUrl2 + `services/images/back/profile-b/${id}`);
  }

  getLastProfiles() {
    return this.http.get(apiUrl + 'users/last');
  }

  getFilteredProfiles(status: number, sortBy: number, skip: number, take: number, age_from, age_to) {

    const data: any = {
      status: status,
      sort_by: sortBy,
      skip: skip,
      take: take,
      age_from: age_from,
      age_to: age_to,
    };

    return this.http.post(apiUrl2 + 'profiles/filter2', data);
  }

  updateProfileField(profile_id: number, field: string, value: any) {
    const data = { profile_id, field, value }

    return this.http.post(apiUrl2 + 'profiles/field/update', data);
  }

  getProfilesMessagesTemplates(profile_id: number) {
    return this.http.get(apiUrl2 + 'profiles/messages_templates/get/' + profile_id);
  }

  declineProfilePost(profile_id: number, post_id: number, decline_reasons: string) {
    const data = {
      profile_id: profile_id,
      post_id: post_id,
      decline_reasons: decline_reasons,
    };

    return this.http.post(apiUrl + 'profiles/post/block', data)
  }

  directLoginWithProfile(user_id: number) {
     return this.http.get(apiUrl + 'profiles/direct_login/' + user_id);
  }

  getProfileLoginsData(data: any) {
       return this.http.post(apiUrl + 'profiles/stats/logins', data);
  }

  getProfileMessagingStats(data: any) {
       return this.http.post(apiUrl + 'profiles/stats/messaging', data);
  }

  getProfileLogsMesStats(data: any) {
       return this.http.post(apiUrl + 'profiles/stats/logs_messages', data);
  }

  getProfileScore(profile_id: number) {
      return this.http.get(apiUrl + 'profiles/score/' + profile_id);
  }

   getUpdatedProfilesLogs() {
      return this.http.get(apiUrl + 'profiles/updated/logs');
  }






  // WALLET

  getWalletTransactions(user_id: number) {
    return this.http.get(apiUrl + 'profiles/get/wallet-transactions/' + user_id);
  }

  getPromoTransactions(user_id: number) {
    return this.http.get(apiUrl + 'profiles/get/promo-transactions/' + user_id);
  }

  chargeWallet(data: any) {
    return this.http.post(apiUrl + 'profiles/charge/wallet', data);
  }

  chargePromo(data: any) {
    return this.http.post(apiUrl + 'profiles/charge/promo', data);
  }


  // USERS
  getUsersAll() {
    return this.http.get(apiUrl + 'users/all');
  }

  getUsersRoles() {
    return this.http.get(apiUrl + 'users/roles');
  }

  userCreate(data: any) {
    // data = {
    //   username: str
    //   email: str
    //   role_id: int
    //   password: str
    // }
    return this.http.post(apiUrl + 'user/create', data);
  }


  answerCreate(data: any) {
    return this.http.post(apiUrl + 'settings/answer/create', data);
  }

  userUpdate(data: any) {
    // data = {
    //   id: int
    //   username: str
    //   email: str
    //   role_id: int
    //   status: int
    // }
    return this.http.post(apiUrl + 'user/update', data);
  }

 // PRODUCTS

  getAllProducts() {
    return this.http.get(apiUrl + 'products/all');
  }

  createProduct(data: any) {
    // data = {
    //   name: str
    //   price: int
    //   tokens: int
    // }
    return this.http.post(apiUrl + 'product/create', data);
  }


  updateProduct(data: any) {
    // data = {
    //   id: int
    //   name: str
    //   price: int
    //   tokens: int
    // }
    return this.http.post(apiUrl + 'product/update', data);
  }

  createChatTemplate(data: any) {
      return this.http.post(apiUrl + 'chat/chats/create/template', data);
  }

  updateChatTemplate(data: any) {
     return this.http.post(apiUrl + 'chat/chats/update/template', data);
  }

  checkSubscription(data: any) {
        return this.http.post(rootUrl + 'user/subscription/check', data);
  }

  addNotificationSubscription(sub: any) {
        return this.http.post(rootUrl + 'user/push-notifications/save', sub);
  }

  sendNotification(data: any) {
        return this.http.post(rootUrl + 'user/push-notifications/send', data);
  }



  // SETTINGS
  getSettingsFrontend() {
    return this.http.get(apiUrl + 'settings/frontend/all');
  }
  updateSettingsFrontend(data) {
    return this.http.post(apiUrl + 'settings/frontend/update', data);
  }
  getLandersAll() {
    return this.http.get(apiUrl + 'settings/landers/all');
  }
  updateLander(data) {
    return this.http.post(apiUrl + 'settings/landers/update', data);
  }
  getSettingsEmail() {
    return this.http.get(apiUrl + 'settings/email/all');
  }
  updateSettingsEmail(data: any) {
    return this.http.post(apiUrl + 'settings/email/update', data);
  }
  updateSettingsEmailMulti(data: any) {
    return this.http.post(apiUrl + 'settings/email/updateMulti', data);
  }

  getLanderAnswers() {
      return this.http.get(apiUrl + 'settings/landers/answers');
  }

  updateLanderAnswers(data: any) {
       return this.http.post(apiUrl + 'settings/lander/answer/update', data)
  }

  //GROUPS
  getAllGroups() {
    return this.http.get(apiUrl + 'settings/groups/all');
  }

  createGroup(data: any) {
    return this.http.post(apiUrl + 'settings/group/create', data);
  }


  updateGroup(data: any) {
    return this.http.post(apiUrl + 'settings/group/update', data);
  }

  //CHATTER STATUSES
  getAllChatterStatuses() {
    return this.http.get(apiUrl + 'settings/chatter_status/all');
  }

  createChatterStatus(data: any) {
    return this.http.post(apiUrl + 'settings/chatter_status/create', data);
  }


  updateChatterStatus(data: any) {
    return this.http.post(apiUrl + 'settings/chatter_status/update', data);
  }

  //CHATTER STATUSES LOGS
  updateChatterStatusLog(data: any) {
    return this.http.post(apiUrl + `chatter_status/update`, data);
  }

  // AFFILIATES
  getAffiliatesAll() {
    return this.http.get(apiUrl + 'affiliates/all');
  }
  getAffiliate(affiliate_id) {
    return this.http.get(apiUrl + 'affiliates/get/' + affiliate_id);
  }
  newAffiliate(data) {
    return this.http.post(apiUrl + 'affiliates/new', data);
  }
  updateAffiliate(data) {
    return this.http.post(apiUrl + 'affiliate/update', data);
  }
  updatePayout(data) {
    return this.http.post(apiUrl + 'affiliate/payout/update', data);
  }


  // INFO
  getTicketsInfo() {
    return this.http.get(apiUrl2 + 'tickets/info');
  }
  createTicket(data: any) {
    return this.http.post(apiUrl2 + 'tickets/create', data);
  }

  // CHATS

  searchChattingProfiles(q: string) {
    return this.http.get(apiUrl + 'chat/chats/search/' + q);
  }

  getChatsSelectedProfile(data) {
    return this.http.post(apiUrl + 'chat/messages', data)
  }

  getChats(profile_id: number, skip: number = 0, take: number = 300, app_id: number = 1) {
    return this.http.get(apiUrl + 'chat/chats/profile/' + profile_id + '/' + skip +  '/' + take + '/' + app_id)
  }

  searchChat(search: string, profile_id: number, app_id: number) {
       return this.http.get(apiUrl + 'chats/search/' + search + '/' + profile_id + '/' + app_id)
  }

 // sendMessage(data) {
 //    return this.http.post(apiUrl + 'chat/chats/send/message', data)
 //  }

   getSentImages(data) {
    return this.http.post(apiUrl + 'chat/messages/images', data)
  }

   editMessage(data) {
    return this.http.post(apiUrl + 'chat/chats/edit/message', data)
  }

  getProfilesWithOutChat(profile_id: number, q: string) {
    return this.http.get(apiUrl + 'chat/chats/profiles/search/' + profile_id + '/' + q)
  }

 getOnlineProfilesAndPendingMessages(messages_all) {
    return this.http.get(apiUrl + 'chats/profiles/list/' + messages_all)
 }


 // getChattingsProfileTicketsList(follow_up: boolean) {
 //    return this.http.get(apiUrl + 'chats/tickets/list/' + follow_up)
 // }
 getChattingsProfileTicketsList(follow_up: boolean) {

    let data = {
      chats_app_ids: [2]
    }

    return this.http.post(apiUrl + 'chat/ticket', data);
 }
 resetChattingsTickets() {
    return this.http.get(apiUrl + 'chat/ticket/reset');
 }


 getChattingsProfileTicketsListFull() {
    return this.http.get(apiUrl + 'chat/tickets/full')
 }

 createChattingSessions(sender_profile_id, recipient_profile_id, app_id) {
    return this.http.get(apiUrl + 'chat/session/create/' + sender_profile_id + '/' + recipient_profile_id + '/' + app_id)
 }

 deleteChattingSessions(profile_id, chatter_id) {
    return this.http.get(apiUrl + 'chats/session/delete/' + profile_id + '/' + chatter_id)
 }

 resetChattingSessions(profile_id) {
    return this.http.get(apiUrl + 'chats/session/reset/' + profile_id)
 }

 validateChatSession(data: any) {
      return this.http.post(apiUrl + 'chat/session/validate', data)
 }

 addReplyLog(data: any) {
    return this.http.post(apiUrl + 'chat/chatter/reply/log', data)
 }

 // getChattingAlerts() {
 //    return this.http.get(apiUrl + 'chats/alerts')
 // }

 getChatDescription(profile_id: number, chatter_id: number, app_id: number = 0) {
  return this.http.get(apiUrl + 'chat/chats/description/get/' + profile_id + '/' + chatter_id + '/'+ app_id);
}

changeChatDescription(data: any) {
  return this.http.post(apiUrl + 'chat/chats/description/change', data);
}

cleanPendingMessages(data: any) {
  return this.http.post(apiUrl + 'chat/chats/read/message', data);
}

getSupportChatInfo(app_id: number) {
    return this.http.get(apiUrl + 'chat/chats/support/basic/' + app_id)
}

getChatsMesTemplates(app_id: number = 0) {
    return this.http.get(apiUrl + 'chat/chats/default/messages/' + app_id)
}

getOfferUrl(data: any) {
    return this.http.post(apiUrl + 'chat/chats/support/offer', data)
}

clearChatPendingMessages(data: any) {
    return this.http.post(apiUrl + 'chat/messages/pending/clear', data)
}

getChattingProgress(data: any) {
      ///old
  return this.http.post(apiUrl + 'chat/chats/chatters/progress', data)
}

getChattingProgressNew(data: any) {
  return this.http.post(apiUrl + 'chat/chats/chatters/progress', data)
}

getChattingProgressStats(data: any) {
  return this.http.post(apiUrl + 'stats/chatters/info', data)
}


getCustomerPurchasesPerChatter(app_id: number) {
  return this.http.get(apiUrl + 'chat/chats/chatters/purchases/' + app_id)
}


getProfileDetail(profile_id: number, app_id: number = 1) {
    return this.http.get(apiUrl + 'chat/profiles/detail/' + profile_id + '/' + app_id)
}

getSingleChat(ticket_id: number, view: boolean = false) {
    return this.http.get(apiUrl + 'chat/ticket/single/' + ticket_id +'/'+ view)
}
putSingleChatOnHold(ticket_id: number) {
    return this.http.get(apiUrl + 'chat/ticket/onhold/' + ticket_id)
}

sendMessagesFromTickets(data: any) {
  return this.http.post(apiUrl + 'chat/tickets/reply', data)
}

getChattingCardsInfo() {
   return this.http.get(apiUrl + 'chats/profiles/chatting/cards')
}

getChattingCardsInfoNew() {
   return this.http.get(apiUrl + 'chat/chatting/cards')
}


getFaceList(profile_id: number) {
    return this.http.get(apiUrl2 + 'profiles/faces/get/' + profile_id)
}

swapFace(data: any) {
    return this.http.post( apiUrl2 + 'profiles/profile_edit', data)
}

getResponsesFromChatbot(data: any) {
     return this.http.post( apiUrl3 + 'chats/chatterbot/suggestions', data)
}

trainChatterbot(data: any) {
    return this.http.post(apiUrl3 + 'chats/chatterbot/train', data)
}

getCountOfPendingChats() {
    return this.http.get(apiUrl + 'chats/pending_chats/count')
}

  getAllChatMessages(data: any) {
    return this.http.post(apiUrl + 'chat/messages/all', data);
  }

  searchChatsProfiles(profile_id: number, app_id: number = 0, q: string) {
     let search_str: string = app_id || !!q ? '?q=' + q : "";
    return this.http.get(apiUrl + 'chats/messages/chatters/search/' + profile_id + '/' + app_id + search_str);
  }

  getDataOfPendingChats() {
       return this.http.get(apiUrl + 'chat/pending');
  }

  getChatTicket (data: any) {
       return this.http.post(apiUrl + 'chat/ticket', data);
  }





 // STATS
  getUserTransactions(user_id: number) {
    return this.http.get(apiUrl + 'stats/dashboard/transactions/user/' + user_id);
  }

  searchResponsesChattersProfiles(chatters_role: boolean, q: string) {
    let urlQ: string = chatters_role || !!q ? '?q=' + q : "";
    return this.http.get(apiUrl + 'stats/responses/search/' + chatters_role + urlQ);
  }

  searchResponsesProfiles(q: string) {
    return this.http.get(apiUrl + 'stats/responses/profiles/search/' + q);
  }

  getProfileMessages(data: any){
     return this.http.post(apiUrl + 'stats/profile/responses', data)
  }

  getAllChats(data) {
    return this.http.post(apiUrl + 'stats/all/responses', data);
  }

  getPostBacksDetail(data: any) {
   return this.http.post(apiUrl + 'stats/track/postbacks', data)
  }

  getTrackVisitsDetail(data: any) {
    return this.http.post(apiUrl + 'stats/track_visits', data)
  }

  getAdniumStats(data: any) {
    return this.http.post(apiUrl + 'stats/adnium/ads/get', data)
  }

  getTrackRegistrationsDetail(data: any) {
   return this.http.post(apiUrl + 'stats/track/registrations', data)
  }

  updateTrackRegistration(data: any) {
    return this.http.post(apiUrl + 'stats/track/registrations/update', data)
  }

  getRegistrationsCsv(data: any) {
    return this.http.post(apiUrl + 'stats/registrations-csv', data);
  }

  getGeneralTraffic(data: any) {
    return this.http.post(apiUrl + 'stats/traffic', data)
   }
  getStatsContent(data: any) {
    return this.http.post(apiUrl + 'stats/content', data)
  }

  getUsersData(data: any) {
     return this.http.post(apiUrl + 'stats/users/info', data)
  }
  getStatsChart(data: any) {
    return this.http.post(apiUrl + 'stats/profit/chart', data)
  }
  getStatsMessaging(data: any) {
    return this.http.post(apiUrl + 'stats/messaging', data)
  }

  getStatsMessagingGraph(data: any) {
    return this.http.post(apiUrl + 'stats/messaging/graph', data);
  }

  getStatsChats(limit: number) {
    return this.http.get(apiUrl + 'stats/chats/responses/' + limit)
  }

  getStatsChart3Data() {
    return this.http.get(apiUrl + 'stats/dashboard/chart3')
  }

  getCountOfUnsentMes() {
    return this.http.get(apiUrl + 'stats/dashboard/unsent_mes')
  }

  getStatsChart4Data() {
    return this.http.get(apiUrl + 'stats/dashboard/chart4')
  }

  getStatsChargebacks(data: any) {
    return this.http.post(apiUrl + 'stats/chargebacks', data)
  }

  updateProfileFaceStatus(data: any) {
    return this.http.post(apiUrl + 'settings/faces/update_profile_face_status', data)
  }

  getAllFaceList() {
    return this.http.get(apiUrl + 'settings/faces/get_all_faces')
  }

  getFacesEthnicities(){
    return this.http.get(apiUrl + 'settings/faces/ethnicities')
  }

  uploadFacesImages(data: any) {
    return this.http.post(apiUrl + 'settings/faces/upload_profile_face', data)
  }

  getWelcomePages() {
       return this.http.get(apiUrl + 'settings/welcome_pages')
  }

  updateWelcomePages(data: any) {
       return this.http.post(apiUrl + 'settings/welcome_pages/update', data)
  }

  createWelcomePages(data: any) {
       return this.http.post(apiUrl + 'settings/welcome_pages/create', data)
  }

  // CONTENT
  getContentProfiles(data: any) {
    /* data = {
        profile_id: int
        profile_id_2: Optional[int] = 0
        type: Optional[int] = 0
        skip_end: Optional[int] = 0
        start_date: Optional[datetime] = datetime.today()
        end_date: Optional[datetime] = datetime.today() } */
    return this.http.post(apiUrl + 'content/profiles/filter', data);
  }

  getMessagesTemplates(profile_id) {
    return this.http.get(apiUrl + 'content/profile/messages/' + profile_id)
  }

  sentMessagesTemplates(data) {
    /*data= {
      profile_id: number,
      content1: string,
      content2: string,
      content3: string,
      content4: string,
      content5: string,
      post1: string
      post2: string
      post3: string
      story1: string
      story2: string
      story3: string
      group1: int,
      group2: int,
      group3: int,

    }*/
    return this.http.post(apiUrl + 'content/profile/message/update', data)
  }

  getContents(data: any) {
    /* data = {
        date_from: str
        date_to: str
        status: bool = false */
    return this.http.post(apiUrl + 'content/all', data);
  }

  getCountOfValidatedMessages() {
    return this.http.get(apiUrl + 'content/basics');
  }

  getFallbackImages(q) {
    return this.http.get(apiUrl + 'content/images/search/' + q);
  }

  getGroups() {
     return this.http.get(apiUrl + 'content/post/groups');
 }

 getDataContentGraph() {
    return this.http.get(apiUrl + 'content/validated/graph')
 }

 getPerformanceGraphData(data: any) {
      return this.http.post(apiUrl + 'stats/performance/graph', data)
 }

 getPerformanceMainGraphData(data: any) {
      return this.http.post(apiUrl + 'stats/performance/main/graph', data)
 }

 getDashboardTrafficGraphData(data: any) {
      return this.http.post(apiUrl + 'stats/dashboard/graph/traffic', data)
 }

 getTrafficGraphData(data: any) {
      return this.http.post(apiUrl + 'stats/traffic/graph', data)
 }

 getValidatedContentGraphData() {
      return this.http.get(apiUrl + 'stats/dashboard/content_graph')
 }

 getLastResponses() {
      return this.http.get(apiUrl + 'stats/responses/last_replies')
 }

 getBestUsers() {
      return this.http.get(apiUrl + 'stats/score/best_users')
 }

 getStatsUsersScore(data: any) {
      return this.http.post(apiUrl + 'stats/users/score', data)
 }

 getStatsUsersAvgScore(data: any) {
      return this.http.post(apiUrl + 'stats/users/score/avg', data)
 }

 getStatsUsersScoreMessaging(data: any) {
      return this.http.post(apiUrl + 'stats/users/score/messaging',  data)
 }

 checkUsersMessagingStats(user_id: number) {
      return this.http.get(apiUrl + 'stats/score/check/stats/' +  user_id)
 }








  // FALLBACK IMAGES
  getFallbackImagesBasics(app_id: number = 0) {
    return this.http.get(apiUrl + 'fallbackImages/basics/' + app_id);
  }
  uploadFallbackImages(data: any) {
    return this.http.post(apiUrl + 'fallbackImages/upload', data);
  }
  getFallbackTypeImages(type_id: number, skip: number, take: number, app_id: number = 0) {
    return this.http.get(apiUrl + 'fallbackImages/type/' + type_id + '/' + skip + '/' + take + '/' + app_id);
  }
  getFallbackProfileImages(profile_id: number) {
    return this.http.get(apiUrl + 'fallbackImages/profile/' + profile_id);
  }
  searchFallbackProfiles(search: string) {
    return this.http.get(apiUrl + 'fallbackImages/profiles/search/' + search);
  }
  assignFallbackImages(image_id: number, profile_id: number, app_id: number = 0) {
    return this.http.get(apiUrl + 'fallbackImages/assign/' + image_id + '/' + profile_id + '/' + app_id);
  }
  unassignFallbackImages(id: number) {
    return this.http.get(apiUrl + 'fallbackImages/unassign/' + id);
  }
  disableFallbackImages(id: number, decline_reasons: string) {
    return this.http.get(apiUrl + 'fallbackImages/disable/' + id + '/' + decline_reasons);
  }
  addFallbackImagesCategory(name: string) {
    return this.http.get(apiUrl + 'fallbackImages/category/add/' + name);
  }
  disableFallbackImagesCategory(type_id: number) {
    return this.http.get(apiUrl + 'fallbackImages/category/disable/' + type_id);
  }
  manageFallbackImagesCategory(type_id: number, name) {
    return this.http.get(apiUrl + 'fallbackImages/category/manage/' + type_id + '/' + name);
  }

  // DASHBOARD CHATTERS

  getChattersProfiles() {
    return this.http.get(apiUrl + 'stats_chatters/chatters/search');
  }

  getDashboardChattersInfo1(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/dashboard/1', data);
  }
  getDashboardChattersInfo2(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/dashboard/2', data);
  }
  getDashboardChattersInfo3(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/dashboard/3', data);
  }

  getChattersData(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/chatters/all', data);
  }

  getChattersTotalData(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/chatters/total', data);
  }

  getChatterData(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/chatters/chatter', data);
  }

  getMessagesData(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/messages', data);
  }

  getMessagePerChatterData(data: any) {
    return this.http.post(apiUrl + 'stats_chatters/messages/chatter', data);
  }

  getValidatedProfiles(data) {
    return this.http.post(apiUrl + 'stats_chatters/profiles/validated', data);
  }

  getValidatedProfilesByChatter(data) {
    return this.http.post(apiUrl + 'stats_chatters/profiles/shatter/validated', data);
  }

  getProfilesMessagesData() {
     return this.http.get(apiUrl + 'stats_chatters/messages/virtual_profiles');
  }

}
